import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'
import { logger } from '../logger'
import { runAsyncWithRetries } from '../../utils/promises'
import {
  defaultFeatureVariants,
  Feature,
  FeatureToVariant,
  FeatureVariants,
} from './Experiments.consts'
import storageManager from '../StorageManager'
import { LANGUAGE_LOCALES, LANGUAGES } from '../../locale/Translation.consts'
import { getLocale } from '../countryService'
import { translateManager } from '../TranslateManager'
import { Subject } from 'rxjs'
import { INITIAL_USER_KEY } from '../userEmail'
import { getUrlParam } from '../../utils/UrlUtils'

const FORCED_VARIANTS_STORAGE_KEY = 'forcedVariants'

const onExperimentFetchSubject = new Subject()

export class ExperimentManager {
  private experimentClient: ExperimentClient = {} as ExperimentClient

  private forcedVariants: FeatureToVariant =
    storageManager.get(FORCED_VARIANTS_STORAGE_KEY) || {}

  async init(userId: string) {
    if (!window.KEYS.amplitudeDeploymentKey) {
      logger.warning('amplitude deployment key is not set')
      return
    }
    this.experimentClient = Experiment.initializeWithAmplitudeAnalytics(
      window.KEYS.amplitudeDeploymentKey,
      {
        fetchTimeoutMillis: 1000,
        pollOnStart: false,
      }
    )

    if (window.KEYS.forcedCountry) {
      storageManager.remove(FORCED_VARIANTS_STORAGE_KEY)
    }

    const initialUserId = getUrlParam('initial_user_id')
    if (initialUserId) {
      // This is the case when the user came from an email ad, and we want to keep the experiments consistent for this user
      storageManager.set(INITIAL_USER_KEY, initialUserId)
    }

    try {
      const { country } = getLocale()
      const user_id = storageManager.get(INITIAL_USER_KEY) || userId
      await runAsyncWithRetries({
        opName: 'initialize amplitude experiments sdk',
        op: () =>
          this.experimentClient.start({
            user_id,
            country,
            language:
              LANGUAGE_LOCALES[translateManager.getLanguage() as LANGUAGES],
          }),
      })
    } catch (e) {
      logger.error('failed starting amplitude experiments sdk', e)
    }
  }

  async fetch<F extends Feature>(userId: string, features?: F[]) {
    await runAsyncWithRetries({
      opName: 'fetching experiments',
      op: () =>
        this.experimentClient.fetch(
          { user_id: userId },
          features && features.length > 0 ? { flagKeys: features } : undefined
        ),
      logMetadata: {
        fetchUserId: userId,
      },
    })
    onExperimentFetchSubject.next()
  }

  subscribeToExperimentFetch(callback: () => void) {
    return onExperimentFetchSubject.subscribe(callback)
  }

  getVariantKey<F extends Feature>(featureName: F): string {
    const forcedVariant = this.forcedVariants[featureName]
    if (forcedVariant) {
      return forcedVariant
    }

    return (
      (this.experimentClient.variant(featureName).key as FeatureVariants[F]) ||
      defaultFeatureVariants[featureName] ||
      'off'
    )
  }

  getVariantPayload<F extends Feature>(featureName: F) {
    return this.experimentClient.variant(featureName).payload
  }

  isEnabled(featureName: Feature): boolean {
    const variant = this.getVariantKey(featureName)
    return variant.includes('treatment') || variant === 'on'
  }

  forceVariant<F extends Feature>(feature: F, variant: FeatureVariants[F]) {
    this.forcedVariants[feature] = variant
    storageManager.set(FORCED_VARIANTS_STORAGE_KEY, this.forcedVariants)
  }

  getAllFeatures() {
    return Object.keys(this.experimentClient.all())
  }

  getUserProperties() {
    const userProperties: Record<string, string | undefined> = {}
    Object.entries(this.experimentClient.all()).map(([feature, variant]) => {
      userProperties[`[Feature] ${feature}`] = variant.value
    })
    return userProperties
  }
}

export const experimentManager = new ExperimentManager()
